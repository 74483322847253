import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import URL_UTILITY from '../../../../utility//url.utility';
import addEditEwayBill from '../../generateEwayBill/addEditEwayBill';

export default {
  name: 'batchDetailsRma',
  components: {
    addEditEwayBill
  },
  props: ['batchData','locAccessFlag','batchType',"currentLoc"],
  watch: {
    assetCurrentPage() {
      this.getTransferredAssets();
    },
    assetPerPage() {
      this.assetCurrentPage = 1;
      this.getTransferredAssets();
    }
  },
  data() {
    return {
      loading: false,
      batchNo: null,
      loader: false,
      invoiceNumber: null,
      legalEntity: {
        value: null,
        text: null
      },
      bookType: {
        value: null,
        text: null
      },
      locationFrom: {
        value: null,
        text: null
      },
      locationTo: {
        value: null,
        text: null
      },
      batchStatus: null,
      locationFromAddress: null,
      locationToAddress: null,
      batchDetailsRmaData: [],
      batchDetailsRmaFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class:this.batchData.batch_status!=='Incomplete'?'d-none':''
        },
        {
          key: 'asset_no',
          class: 'text-center'
        },
        {
          key: 'item_name',
          label: 'Asset Type',
          class: 'text-center'
        },
        {
          key: 'serial_no',
          class: 'text-center'
        },
        {
          key: 'batch_num',
          class: 'text-center'
        },
        {
          key: 'batch_date',
          class: 'text-center'
        },
        {
          key: 'remarks',
          class: 'text-center'
        },
        {
          key: 'gross_book_value',
          label: 'Amount',
          class: 'text-center'
        },
        // {
        //   key: 'tax_amount',
        //   label: 'Tax',
        //   class: 'text-center'
        // }
      ],
      allRMA: false,
      replacedData: [],
      replacedFields: [
        {
          key: 'selectBox1',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class:this.batchData.batch_status!=='Incomplete'?'d-none':''
        },
        {
          key: 'asset_no',
          class: 'text-center'
        },
        {
          key: 'item_name',
          label: 'Asset Type',
          class: 'text-center'
        },
        {
          key: 'serial_no',
          class: 'text-center'
        },
        {
          key: 'bacth_date',
          class: 'text-center'
        },
        {
          key: 'same',
          class: 'text-center'
        },
        {
          key: 'new',
          label: 'New Asset Assignment',
          class: 'text-center'
        },
        {
          key:'gross_book_value',
          label:'Amount',
          class: 'text-center'
        },
        {
          key: 'tax_amount',
          label: 'Tax',
          class: 'text-center'
        }
      ],
      ewayBillData: [],
      ewayBillFields: [
        {
          key: 'selectRadio',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'ewb_no',
          label: 'Eway Bill Num'
        },
        {
          key: 'invoice_date',
          label: 'Date'
        },
        {
          key: 'ewb_valid_from',
          label: 'Valid From'
        },
        {
          key: 'ewb_valid_to',
          label: 'Valid Until'
        },
        {
          key: 'trans_name'
        },
        {
          key: 'trans_mode'
        },
        {
          key: 'distance'
        },
        {
          key: 'trans_doc_dt'
        },
        {
          key: 'trans_doc_no',
          label: 'LR Number'
        },
        {
          key: 'veh_no'
        },
        {
          key: 'veh_type'
        },
        {
          key: 'remarks'
        },
        {
          key: 'status'
        },
        {
          key: 'cancel_status'
        },
        {
          key: 'cancel_date'
        }
      ],
      selectedRow: [],
      selectedReplacedRow: [],
      transferHdrId: null,
      assetCurrentPage: 1,
      hrLocId: null,
      index: null,
      valuetype: null,
      vsetCode: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      responseMsg: '',
      transferAssetToRows: null,
      assetPerPage: 10,
      transferType: null,
      fromGstn: null,
      toGstn: null,
      irnNumber: null,
      irnStatus: null,
      irnError: null,
      requestStatus: null,
      defaultTaxGroup: null,
      defaultTaxGroupId: null,
      defaultTaxCategory: null,
      defaultTaxCategoryId: null,
      moduleId: null,
      assetIndex: null,
      allReplacedRMA: false,
      toastMessage: null,
      defaultValue: {
        value: null,
        text: null
      },
      editMode: false,
      showOpenGenerateAwbModal: false,
      ewayBillNo:null,
      disableFlag: false,
      name:null,
      phone:null,
      username: null,
      userphone: null,
      pickupDate: null,
      pickupTime: null,
      isPrintInvoice: false,
      requestId: null,
      templateId: null,
      printInvoiceNumberFlag: false,
      requestNumId: null,
      reqId: null,
      downloadReport: URL_UTILITY.getDownloadReportUrl,
      downloadLog: URL_UTILITY.getDownloadLOGUrl,
      pdfDownloadingLink: null,
      rmaRemarks:null,
      assetNo:null,
      serialNo:null,
      irnRequestId: null,
      irnTemplateId: null,
      showOpenEwayBillModal : false,
      showOpenShipmentTrackingModal: false,
      shipmentTrackingData: [],
      shipmentTrackingFields: [
        {
          key: 'scanDate',
          class: 'text-center'
        },
        {
          key: 'scanTime',
          class: 'text-center'
        },
        {
          key: 'ScanType',
          class: 'text-center'
        },
        {
          key: 'Scan',
          class: 'text-center'
        },
        {
          key: 'StatusDate',
          class: 'text-center'
        },
        {
          key: 'StatusTime',
          class: 'text-center'
        },
        {
          key: 'ScannedLocation',
          class: 'text-center'
        },
        {
          key: 'Instructions',
          class: 'text-center'
        },
        {
          key: 'StatusCode',
          class: 'text-center'
        }
      ],
    };
  },
  mounted() {
    if (this.batchData) {
      this.getParentToChildData(this.batchData);
    }
    this.moduleId = this.$store.state.shared.moduleId;
    this.getTransferredAssets();
    this.getRmaReplacement();
    this.setDefaultTaxGroup();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'delete') {
          this.deleteReplaced();
        }
        else if(actionName === 'save'){
          this.saveProcessTransferredAssets()
        }
      }
    });
  },
  methods: {
    generateEwayBill() {
      this.showOpenEwayBillModal = true;
    },
    showHideShipmentTrackingModal(flag) {
      this.showOpenShipmentTrackingModal = flag;
    },
    openShipmentTrackingModal() {
      this.shipmentTrackingData = [];
      const payload = {
        wayBillNo: this.ewayBillNo
      };
      this.loading = true;
      this.$store
        .dispatch('assets/getShipmentTrackingDetails', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            let result = resp.data.data.ShipmentData[0].Shipment.Scans;
            for (let i of result) {
              this.shipmentTrackingData.push(i.ScanDetail);
            }
            for (let i of this.shipmentTrackingData) {
              const scandate = this.convertISOToDDMMMYYYY(i.ScanDateTime);
              const myArray = i.ScanDateTime.split('T');
              i.scanDate = scandate;
              i.scanTime = myArray[1];
              i.scanTime = i.scanTime.substring(0, 8);
              const statusdate = this.convertISOToDDMMMYYYY(i.StatusDateTime);
              const myArray1 = i.StatusDateTime.split('T');
              i.StatusDate = statusdate;
              i.StatusTime = myArray1[1];
              i.StatusTime = i.StatusTime.substring(0, 8);
            }
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
      this.showOpenShipmentTrackingModal = true;
    },
    convertISOToDDMMMYYYY(timestamp) {
      var dt = new Date(timestamp);
      var monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];
      var day = dt.getDate();
      var month = monthNames[dt.getMonth()];
      var year = dt.getFullYear();
      var formattedDate = day + '/' + month + '/' + year;
      return formattedDate;
    },
    checkRMARow(event, row, index) {
      row.selectBox = event;
      if (event) {
        this.selectedRow.push(row);
      } else {
        this.selectedRow.splice(index, 1);
      }
    },
    checkReplacedRow(event, row, index) {
      row.selectBox1 = event;
      if (event) {
        this.selectedReplacedRow.push(row);
      } else {
        this.selectedReplacedRow.splice(index, 1);
      }
    },
    checkAllRMARow(event) {
      // this.selectedRow = [];
      if (event) {
        this.allRMA = event;
        this.batchDetailsRmaData = this.batchDetailsRmaData.map(data => {
          data.selectBox = event;
          return data;
        });
        this.selectedRow = this.batchDetailsRmaData;
      } else {
        this.allRMA = false;
        this.batchDetailsRmaData = this.batchDetailsRmaData.map(data => {
          data.selectBox = false;
          return data;
        });
        this.selectedRow = [];
      }
    },
    checkAllReplacedRow(event) {
      // this.selectedRow = [];
      if (event) {
        this.allReplacedRMA = event;
        this.replacedData = this.replacedData.map(data => {
          data.selectBox1 = event;
          return data;
        });
        this.selectedReplacedRow = this.replacedData;
      } else {
        this.allReplacedRMA = false;
        this.replacedData = this.replacedData.map(data => {
          data.selectBox1 = false;
          return data;
        });
        this.selectedReplacedRow = [];
      }
    },
    getParentToChildData(item) {
      this.batchNo = item.batch_num;
      this.invoiceNumber = item.invoice_number;
      this.legalEntity = {
        value: item.le_id,
        text: item.le_name
      };
      this.bookType = {
        value: item.book_type_code,
        text: item.book_type_code
      };
      this.locationFrom = {
        value: item.location_from_id,
        text: item.location_from
      };
      this.locationTo = {
        value: item.location_to_id,
        text: item.location_to
      };
      this.batchStatus = item.batch_status;
      this.locationFromAddress = item.location_from_address;
      this.locationToAddress = item.location_to_address;
      this.transferHdrId = item.trf_batch_id;
      this.hrLocId = item.location_from_id;
      this.transferType = item.transfer_type;
      this.fromGstn = item.from_gstn_no;
      this.toGstn = item.to_gstn_no;
    },
    getTransferredAssets() {
      const payload = {
        hrLocId: this.hrLocId,
        assetNo: this.assetNo,
        serialNo: this.serialNo,
        pagination: {
          _page: this.assetCurrentPage - 1,
          _limit: this.assetPerPage
        }
      };
      this.loading = true;
      this.$store
        .dispatch('assets/getRMAreplacement', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            const results = response.data.data.page;
            this.batchDetailsRmaData = results;
            this.transferAssetToRows = response.data.data.total_elements;
          }
        })
        .catch(() => (this.loading = false));
    },
    openValueSetModal(vsetCode, index, valuetype) {
      this.index = index;
      this.valuetype = valuetype;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.ASSET_MASTER_DETAILS_VSET) {
        this.parent_value_set_id = this.locationFrom.value;
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET) {
        this.parent_value_set_id = this.defaultTaxGroupId;
        this.taxType = 'IGST';
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.ASSET_MASTER_DETAILS_VSET:
          this.replacedData[this.index].new = {
            value: item.asset_id,
            text: item.asset_no
          };
          break;
        case appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET:
          this.defaultTaxCategory = item.value_code;
          this.defaultTaxCategoryId = item.value_set_dtl_id;
          this.applyTaxCategory(item.value_set_dtl_id);
          break;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    closeRMA() {
      let data = [];
      for (let i of this.selectedRow) {
        data.push({
          trf_hdr_id: i.trf_hdr_id,
          trf_dtl_id: i.trf_dtl_id,
          asset_id: i.asset_id,
          remarks: i.remarks
        });
      }
      const payload = {
        update_rma_status_request_dtl: data
      };
      this.loading = true;
      this.$store
        .dispatch('assets/closeRmaReplacement', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.responseMsg = response.data.message;
            this.makeToast(this.responseMsg, 'success');
            this.getTransferredAssets();
            this.selectedRow = [];
          } else {
            this.responseMsg = response.response.data.message;
            this.makeToast(this.responseMsg, 'warning');
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
    },
    processTransferredAssets() {
      const taxValidate = this.checkTaxApplicable();
      if (taxValidate) {
        const checkTaxApplied = this.batchDetailsRmaData.every(
          key => key.tax_cat_hdr_id
        );
        if (!checkTaxApplied) {
          return alert('Please apply tax category before processing batch!');
        }
      }
      let data = [];
      let misCount=0;
      for (let i of this.replacedData) {
        if(i.new.value==null && !i.same){
           misCount++
        }
        else{
          data.push({
            trx_line_id: i.trf_dtl_id,
            existing_asset_id: i.asset_id,
            new_asset_id: i.new.value ? i.new.value : i.asset_id
          });
        }

      }
      if(misCount>0){
        this.$bvToast.toast('Kindly assign either a New Asset or check the Same selectbox for the not selected assets', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }else{
      const payload = {
        trx_id: this.transferHdrId,
        trx_cat: 'ASSET_TRANSFER',
        trx_replacement_dtl: data
      };
      this.loader=true;
      this.$store
        .dispatch('assets/processTransferRmaReplacement', payload)
        .then(response => {
          this.loader=false;
          if (response.status === 200) {
            this.responseMsg = response.data.message;
            this.makeToast(this.responseMsg, 'success');
            this.invoiceNumber = response.data.data.invoice_number;
            this.batchStatus = response.data.data.batch_status;
            this.getTransferredAssets();
            this.getRmaReplacement();
            this.getIrnProcessById(this.batchData.trf_batch_id);
            // this.getProcessStatusAsset(this.transferHdrId);
            this.getProcessTransfer();
            this.$emit('updateTransferAssetList');
          } else {
            this.responseMsg = response.response.data.message;
            this.makeToast(this.responseMsg, 'warning');
          }
        })
        .catch(err => {
          this.loader=false;
          this.makeToast(err.message, 'danger');
        });
      }
    },
    checkTaxApplicable() {
      if (this.fromGstn && this.toGstn) {
        const fpGstnCode = this.fromGstn.slice(0, 2);
        const tpGstnCode = this.toGstn.slice(0, 2);
        return fpGstnCode === tpGstnCode ? false : true;
      }
      return false;
    },
    getIrnProcessById(trfHdrId) {
      this.loading = true;
      this.$store
        .dispatch('assets/getIrnProcessById', trfHdrId)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            this.irnNumber = resp.data.data.irn;
            this.irnError = resp.data.data.error_msg;
            this.irnStatus = resp.data.data.status;
            this.getTransferDetailsById();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getProcessTransfer() {
      const payload = {
        trfHdrId: this.batchData.trf_batch_id
      };
      this.loading = true;
      this.$store
        .dispatch('assets/getProcessTransfer', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            this.refreshBtn = true;
            this.requestStatus = resp.data.data[0].status;
            this.getIrnProcessById(this.batchData.trf_batch_id);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    addRmaReplacement() {
      let addRmaReplacementData = [];
      for (let i of this.selectedRow) {
        addRmaReplacementData.push({
          asset_id: i.asset_id,
          awb_no: i.awb_no,
          hsn_sac_code: i.hsn_sac_code,
          trf_dtl_id: i.trf_dtl_id,
          description: i.description
        });
      }
      const payload = {
        trx_id: this.batchData.trf_batch_id,
        asset_rma_replacement_details: addRmaReplacementData
      };
      this.loading = true;
      this.$store
        .dispatch('assets/addRmaReplacement', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            this.getTransferredAssets();
            this.getRmaReplacement();
            this.selectedRow = [];
            this.responseMsg = resp.data.message;
            this.makeToast(this.responseMsg, 'success');
          } else {
            this.responseMsg = resp.response.data.message;
            this.makeToast(this.responseMsg, 'warning');
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getRmaReplacement() {
      const payload = {
        trx_id: this.batchData.trf_batch_id
      };
      this.loading = true;
      this.$store
        .dispatch('assets/getRmaReplacement', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            this.replacedData = resp.data.data.page;
            for (let i of this.replacedData) {
              i.new = {
                value: null,
                text: null
              };
              if(i.replaced_asset_id) {
                i.new = {
                  value: i.replaced_asset_id,
                  text: i.replaced_asset_no
                }
                i.same=false
              }
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    setDefaultTaxGroup() {
      const taxGrp = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.TAX_GROUP_NAME_VSET
      );
      taxGrp.then(key => {
        this.defaultTaxGroup = key[0].value_code;
        this.defaultTaxGroupId = key[0].value_set_dtl_id;
      });
    },
    clearVsetValues(vsetCode, index) {     
      if (vsetCode === 'tax_category') {
        this.defaultTaxCategory = null;
        this.defaultTaxCategoryId = null;
      } else if (vsetCode === 'new') {
        this.replacedData[index].new = {
          value: null,
          text: null
        };
      } 
    },
    applyTaxCategory(taxCatHdrId) {
      this.loading = true;
      const filteredDetails = this.replacedData.filter(
        elem => elem.selectBox1
      );
      const details = filteredDetails.map(data => {
        return data.trf_dtl_id;
      });
      const payload = {
        trfHdrId: this.transferHdrId,
        assetDetails: {
          tax_cat_hdr_id: taxCatHdrId,
          trf_dtl_ids: details
        }
      };
      this.$store
        .dispatch('assets/saveAssetTaxCatByTrnsfrBatchId', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.saveTaxDetails();
          }
        })
        .catch(err => {
          this.loading = false;
          alert(err.message);
        });
    },
    saveTaxDetails() {
      this.loader = true;
      const filteredDetails = this.replacedData.filter(elem => {
        return elem.selectBox1;
      });
      const details = filteredDetails.map(elem => {
        return {
          exchange_rate: 1,
          fp_gstn: this.fromGstn,
          hsn: '12345678',
          location_id: this.locationFrom.value,
          module_id: this.moduleId,
          sac: '',
          tax_cat_id: +this.defaultTaxCategoryId,
          tp_gstn: this.toGstn,
          trx_amount: parseFloat(elem.gross_book_value.replaceAll(',', '')),
          trx_cat: 'ASSET_TRANSFER',
          trx_id: elem.trf_hdr_id,
          trx_line_id: elem.trf_dtl_id
        };
      });
      const payload = {
        save_tax_details: details
      };
      this.$store
        .dispatch('assets/saveAssetTaxCategory', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.calculateAssetTax();
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    calculateAssetTax() {
      this.loader = true;
      const filteredDetails = this.replacedData.filter(
        elem => elem.selectBox1
      );
      const details = filteredDetails.map(data => {
        return {
          trx_cat: 'ASSET_TRANSFER',
          trx_id: data.trf_hdr_id,
          trx_line_id: data.trf_dtl_id
        };
      });
      const payload = {
        tax_calculate_details: details
      };
      this.$store
        .dispatch('assets/calculateAssetTaxValue', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            // this.getTransferredAssets();
            this.getRmaReplacement();
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    deleteReplaced() {
      const filterAssets = this.selectedReplacedRow.filter(
        elem => elem.selectBox
      );
      if (filterAssets.length > 0) {
        const details = filterAssets.map(data => {
          return {
            trf_dtl_id: data.trf_dtl_id
          };
        });
        const payload = {
          trfHdrId: this.transferHdrId,
          assetDetails: details
        };
        this.loader = true;
        this.$store
          .dispatch('assets/deleteTransferAssetsDetails', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.toastMessage = response.data.message;
              this.$bvToast.toast(this.toastMessage, {
                title: 'Success',
                variant: 'success',
                solid: true
              })
              this.getRmaReplacement();
              this.getRmaReplacement();
            } else {
              this.$bvToast.toast(this.toastMessage, {
                title: 'Error',
                variant: 'danger',
                solid: true
              })
            }
          })
          .catch(() => (this.loader = false,
            this.$bvToast.toast(this.toastMessage, {
              title: 'Error',
              variant: 'danger',
              solid: true
            })));
      } else {
        this.toastMessage = 'Please select assets to delete';
      }
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true
      });
    },
    createShipment() {
      this.showOpenGenerateAwbModal = true;
    },
    generateAwb() {
      if (this.name && this.phone) {
        const payload = {
          trfHdrId: this.transferHdrId,
          name: this.name,
          phone: this.phone
        };
        this.loader = true;
        this.$store
          .dispatch('assets/createShipment', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 200) {
              this.ewayBillNo = resp.data.data.wayBillNo;
              this.username = resp.data.data.name;
              this.userphone = resp.data.data.phone;
              this.responseMsg = resp.data.message;
              this.makeToast(this.responseMsg, 'success');
            }
          })
          .catch(err => {
            this.loader = false;
            this.makeToast(err.message, 'danger');
          });
        this.showOpenGenerateAwbModal = false;
      } else {
        alert('Please fill in the fields!');
      }
    },
    rejectBatch() {
      const payload = {
        trf_hdr_id: this.transferHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('assets/rejectBatch', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.batchStatus = 'Rejected';
          }
        })
        .catch(err => {
          this.loader = false;
          this.makeToast(err.message, 'danger');
        });
    },
    getPrintTransferAsset(flag) {
      this.isPrintInvoice = flag;
      this.loader = true;
      this.$store
        .dispatch('assets/getPrintTransferAsset')
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.printInvoiceNumberFlag = true;
            this.requestId = resp.data.data.request_id;
            this.templateId = resp.data.data.template_id;
            this.saveSubmitRequest();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveSubmitRequest() {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap', //pass hardcode
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'PDF', //pass hardcode
            no_of_args: 25,
            request_parameter: this.batchData.trf_batch_id,
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: this.batchData.trf_batch_id,
            argument2: null,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            this.getSubmitRequest();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubmitRequest() {
      this.loader = true;
      this.$store
        .dispatch('template/getSubmitRequest', this.requestNumId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.requestStatus = resp.data.data[0].status;
            this.reqId = resp.data.data[0].id;
          }
          this.getIrnProcessById(this.batchData.trf_batch_id);
        })
        .catch(() => {
          this.loader = false;
        });
    },
    printLabel() {
      const payload = {
        waybill: this.ewayBillNo
      };
      this.loader = true;
      this.$store
        .dispatch('assets/printLabel', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.pdfDownloadingLink =
              resp.data.data.packages[0].pdf_download_link;
            window.open(this.pdfDownloadingLink, '_label');
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    clear() {
      this.assetNo = null;
      this.serialNo = null;
    },
    getTransferDetailsById() {
      const payload = {
        trfHdrId: this.batchData.trf_batch_id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getTransferDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.batchStatus = result.batch_status_meaning;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getIrnRequest() {
      const payload = {
        trfHdrId: this.batchData.trf_batch_id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getIrnRequest',payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.irnRequestId = resp.data.data.request_id;
            this.irnTemplateId = resp.data.data.template_id;
            // this.irnSaveSubmitRequest();
            this.getTransferDetailsById();

          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // irnSaveSubmitRequest() {
    //   const payload = {
    //     admin_submit_req_details: [
    //       {
    //         request_num: 0,
    //         request_id: this.irnRequestId,
    //         template_id: this.irnTemplateId,
    //         schedule_type: 'asap', //pass hardcode
    //         request_start_date: null,
    //         request_end_date: null,
    //         resubmit_interval: null,
    //         resubmit_interval_unit: null,
    //         day_of_month: null,
    //         day_of_week: null,
    //         output_format_id: 'SBLANK', //pass hardcode
    //         no_of_args: 25,
    //         request_parameter: 'GENERATEIRN,FA,' + this.batchData.trf_batch_id,
    //         sms_flag: false,
    //         email_flag: false,
    //         whatsapp_flag: false,
    //         child_req_count: null,
    //         email_comm_template_id: null,
    //         sms_comm_template_id: null,
    //         whatsapp_comm_template_id: null,
    //         argument1: 'GENERATEIRN',
    //         argument2: 'FA',
    //         argument3: this.batchData.trf_batch_id,
    //         argument4: null,
    //         argument5: null,
    //         argument6: null,
    //         argument7: null,
    //         argument8: null,
    //         argument9: null,
    //         argument10: null,
    //         argument11: null,
    //         argument12: null,
    //         argument13: null,
    //         argument14: null,
    //         argument15: null,
    //         argument16: null,
    //         argument17: null,
    //         argument18: null,
    //         argument19: null,
    //         argument20: null,
    //         argument21: null,
    //         argument22: null,
    //         argument23: null,
    //         argument24: null,
    //         argument25: null
    //       }
    //     ]
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('template/irnSaveSubmitRequest', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 201) {
    //         this.requestNumId = resp.data.data[0].id;
    //         this.getSubmitRequest();
    //         this.getTransferDetailsById();
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    saveProcessTransferredAssets() {
      let data1 = [];
      let misCount1=0;
      for (let i of this.replacedData) {
        if(i.new.value==null && !i.same){
           misCount1++
        }
        else{
          data1.push({
            trx_line_id: i.trf_dtl_id,
            existing_asset_id: i.asset_id,
            new_asset_id: Number(i.new.value ? i.new.value : i.asset_id)
          });
        }

      }
      if(misCount1>0){
        this.$bvToast.toast('Kindly assign either a New Asset or check the Same selectbox for the not selected assets', {
          title: 'Error',
          variant: 'danger',
          solid: true
        })
      }else{
      const payload = {
        trx_id: this.transferHdrId,
        trx_cat: 'ASSET_TRANSFER',
        trx_replacement_dtl: data1
      };
      this.loading = true;
      this.$store
        .dispatch('assets/saveProcessTransferRmaReplacement', payload)
        .then(response => {
          this.loading = false;
          if (response.status === 200) {
            this.responseMsg = response.data.message;
            this.makeToast(this.responseMsg, 'success');
            // this.invoiceNumber = response.data.data.invoice_number;
            // this.batchStatus = response.data.data.batch_status;
            this.getTransferredAssets();
            this.getRmaReplacement();
            this.getIrnProcessById(this.batchData.trf_batch_id);
            // this.getProcessStatusAsset(this.transferHdrId);
            // this.getProcessTransfer();
            // this.$emit('updateTransferAssetList');
          } else {
            this.responseMsg = response.response.data.message;
            this.makeToast(this.responseMsg, 'warning');
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
      }
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
